import { ClienteTipo } from 'src/model/enums/cliente-tipo.enum';
import { MainComponent } from './../main.component';
import { AuthService } from '../../../../services/auth.service';
import { MessageService } from 'primeng/api';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { EmpresaService } from 'src/services/empresas.service';
import { Empresa } from 'src/model/empresa.model';
import { EmpresaPagedRequest } from 'src/model/empresa-paged-request.model';
import { Page } from 'src/model/page.model';
import { Cliente } from 'src/model/cliente.model';
import { CertificadoEmpresa } from 'src/model/certificado-empresa.model';

@Component({
  selector: 'app-minhas-empresas',
  templateUrl: './minhas-empresas.component.html',
  styleUrls: ['./minhas-empresas.component.scss']
})
export class MinhasEmpresasComponent implements OnInit {

  @ViewChild('dt') table: Table | undefined;

  loading = false;
  timeout: any;
  timerBusca: any;

  showModalEmpresa: boolean = false;

  empresa: Empresa | undefined;
  empresas: Page<Empresa> = new Page<Empresa>();
  empresaRequest: EmpresaPagedRequest = new EmpresaPagedRequest();
  empresaNova = false;

  loggedUser: Cliente | undefined;
  ClienteTipo = ClienteTipo;
  somenteVisualizar = false;

  constructor(
    private empresaService: EmpresaService,
    private messageService: MessageService,
    private authService: AuthService,
    private main: MainComponent
  ) { }

  ngOnInit(): void {
    this.loadEmpresas();
    this.loggedUser = this.authService.getUsuario()!;
  }

  loadEmpresas() {
    this.loading = true;
    this.showModalLoading();
    this.empresaRequest.clienteLogin = this.authService.getUsuario()?.login;
    this.empresaRequest.unpaged = true;
    this.empresaRequest.sortBy = ["nome;asc"]
    this.empresaService.getEmpresasByFilters(this.empresaRequest).subscribe({
      next: (data) => {
        setTimeout(() => {
          this.empresas = data;
        });
        this.loading = false;
        this.hideModalLoading();
      },
      error: (e) => {
        this.showMensagemErro(e.name, e.message);
      }
    }).add(() => {
      this.hideModalLoading();
    });
  }

  procura(event: any) {
    this.table?.filterGlobal(event.target.value, "contains");
  }

  clear(table: Table) {
    table.clear();
  }

  salvarEmpresa(empresa: Empresa) {
    this.empresa = empresa;
    if (this.empresaNova) {
      this.empresaService.criaEmpresa(this.empresa).subscribe({
        next: (response) => {
          if (response.successfully) {
            this.refresh();
            this.showMensagemSucesso("Empresa cadastrada com sucesso");
          } else {
            this.showMensagemErro("Erro ao salvar", response.result);
          }
        },
        error: (e) => {
          this.showMensagemErro(e.name, e.message);
        }
      });
    } else {
      this.empresaService.atualizaEmpresa(this.empresa).subscribe({
        next: (response) => {
          if (response.successfully) {
            if (empresa) {
              const index = this.empresas.content.findIndex(c => c.id === empresa.id);
              if (index !== -1) {
                this.empresas.content[index] = empresa;
              }
            } else {
              this.refresh();
            }
            this.showMensagemSucesso("Empresa atualizada com sucesso");
          } else {
            this.showMensagemErro("Erro ao salvar", response.result);
          }
        },
        error: (e) => {
          this.showMensagemErro(e.name, e.message);
        }
      });
    }
  }

  refresh() {
    this.loadEmpresas();
  }

  editaEmpresa(empresa: Empresa) {
    this.empresa = Object.assign({}, empresa);;
    this.openModalCadastroEmpresa(true);
  }

  openModalCadastroEmpresa(editando: boolean) {
    this.empresaNova = !editando;
    if (this.empresaNova) {
      this.empresa = new Empresa();
    }
    this.showModalEmpresa = true;
  }

  closeModalEmpresaContador() {
    this.showModalEmpresa = false;
  }

  private showMensagemSucesso(mensagem: string) {
    this.messageService.add({
      key: 'to-message-service',
      severity: 'success',
      summary: '',
      detail: mensagem,
      life: 5000
    });
  }

  private showMensagemErro(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'toastBottomRigth800px',
      severity: 'error',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 10000
    });
  }

  public dataVencida(certificado: CertificadoEmpresa): boolean {
    if (certificado != null && certificado.validoAte) {
      return (new Date(certificado.validoAte).getTime() < new Date().getTime());
    } else {
      return false;
    }
  }

  private showModalLoading() {
    this.loading = this.main.showModalLoading();
  }

  private hideModalLoading() {
    this.loading = this.main.hideModalLoading();
  }

}
