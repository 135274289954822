<div class="container">
    <div class="container-width">
        <!-- TITULO -->
        <div class="d-flex flex-row align-items-baseline" style="margin-top: -10px;">
            <div class="flex-grow-1">
                <div>
                    <h4 style="line-height: 2">
                        Visão geral<span style="color: gray;"> - {{ userLogged.nome }}</span>
                    </h4>
                </div>
            </div>
        </div>
    <div>
    <p-panelMenu [model]="panelMenuContent" data-p-highlight="true" aria-expanded="true">
        <ng-template pTemplate="item" let-panelMenuContent>
            <div class="panelMenuContent" *ngIf="panelMenuContent.textContent">
                <i [class]="panelMenuContent.icon"></i>
                <span [innerHTML]="panelMenuContent.textContent"></span>
                <p-badge [value]="panelMenuContent.badgeContent"/>
            </div>
        </ng-template>
    </p-panelMenu>
    </div>
        <div>
            <div>
                <p-table [defaultSortOrder]="-1" [value]="dashboardContent" styleClass="p-datatable-gridlines">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Tipo</th>
                            <th pSortableColumn="quantidadeDocs">Volumetria XMLs (Qtde)<p-sortIcon field="quantidadeDocs"></p-sortIcon></th>
                            <th pSortableColumn="valorTotal">Valor Total (R$)<p-sortIcon field="valorTotal"></p-sortIcon></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-dashboardContent>
                        <p-progressSpinner *ngIf="!dashboardContent.conteudoCarregou" animationDuration=".5s" />
                        <tr [ngClass]="{'loading-row': !dashboardContent.conteudoCarregou}">
                            <td>
                              <span class="dashboard-tag" [ngStyle]="{'color': escurecerCor(dashboardContent.cor, 0.5), 'background-color': dashboardContent.cor}">
                                <b>{{ dashboardContent.tipoDoc }}</b>
                              </span>
                            </td>
                            <td>{{ dashboardContent.quantidadeDocs }}</td>
                            <td>{{ dashboardContent.valorTotal | currency : 'BRL' }}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            <div style="margin-top: 30px;">
                <p-chart width="500" height="500" type="bar" [data]="chartData" [options]="chartOptions"></p-chart>
            </div>
        </div>
    </div>
</div>
