export class NfseMunicipio {

  emiteNfseManualmente?: boolean;
  codigoMun?: number;
  login?: string;
  senha?: string;
  inscricaoMunicipal?: string;
  dataRegistro?: Date;
  temTomada: boolean | undefined;
  temPrestada: boolean | undefined;
}
