<div class="container">
  <div class="container-width">
    <!-- TITULO -->
    <div class="d-flex flex-row align-items-baseline" style="margin-top: -10px;">
      <div class="flex-grow-1">
        <div>
          <h4 style="line-height: 2">Importação e exportação de XMLs</h4>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-3 d-flex column-gap-3" style="margin-left: 20px;">
    <div>
      <p-fieldset legend="Importação">
        <div>
          <p-dropdown
            [style]="{'margin': '0px 5px 5px 0px'}"
            [options]="tipoDocumentoImportacao"
            [(ngModel)]="selectedTipoDocImportacao"
            optionLabel="name"
            [showClear]="selectedTipoDocImportacao"
            (onChange)="carregaCampoMunicipiosContratados()"
            placeholder="Selecione o Tipo">
          </p-dropdown>
          <p-dropdown
            [autoDisplayFirst]="true"
            [options]="municipiosContratados"
            [editable]="false"
            [(ngModel)]="selectedMunicipio"
            [ngModelOptions]="{standalone: true}"
            optionLabel="municipio"
            placeholder="Selecione o município"
            [ngClass]="selectedTipoDocImportacao && selectedTipoDocImportacao.key === 3 ? '' : 'd-none'">
          </p-dropdown>
          <p-fileUpload
            [multiple]="true"
            accept=".xml"
            [customUpload]="true"
            (uploadHandler)="onUploadXml($event)"
            chooseLabel="Escolher arquivos">
          </p-fileUpload>
        </div>
      </p-fieldset>
    </div>
    <div>
      <p-fieldset legend="Exportação">
        <div style="width: 420px;">
          <div>
            <div class="input-date-box">
              <label for="dataInicioExportacaoInput">Data inicial</label>
              <p-calendar
                aria-label="Date"
                [(ngModel)]="dataInicioExportacao"
                [showIcon]="true"
                inputId="dataInicioExportacaoInput"
                dateFormat="dd/mm/yy">
              </p-calendar>
            </div>
            <div class="input-date-box" style="margin-top: 10px;">
              <label for="dataFinalExportacaoInput">Data final</label>
              <p-calendar
                aria-label="Date"
                [(ngModel)]="dataFinalExportacao"
                [showIcon]="true"
                inputId="dataFinalExportacaoInput"
                dateFormat="dd/mm/yy">
              </p-calendar>
            </div>
          </div>
          <div id="radio-button-box">
            <div *ngFor="let docExportacao of tipoDocumentoExportacao">
              <p-radioButton
                (onClick)="onClickRadioButtonDocExportar(docExportacao)"
                [inputId]="docExportacao.key.toString()"
                [value]="docExportacao"
                [(ngModel)]="selectedTipoDocExportacao">
              </p-radioButton>
              <label style="margin-left: 5px;" [for]="docExportacao.key">{{ docExportacao.name }}</label>
            </div>
          </div>
        </div>
        <div style="height: 40px;">
            <div style="display: flex; gap: 6px; align-items: center;">
                <p-checkbox
                    [disabled]="selectedTipoDocExportacao.key != 3"
                    [binary]="true"
                    inputId="abrasfXMLOption"
                    [(ngModel)]="abrasfXMLOption"
                    label="Baixar XML no padrão ABRASF">
                  </p-checkbox>
            </div>
        </div>
        <div id="export-buttons-box">
            <p-button label="Exportar XMLs" icon="pi pi-chevron-down" (onClick)="downloadXMLOpt.toggle($event)"/>
            <!-- <p-button label="Exportar PDFs" icon="pi pi-chevron-down" /> -->
        </div>
        <p-overlayPanel #downloadXMLOpt [style]="{ 'margin-top':'30px', 'margin-left':'80px' }">
            <div>
                <p>De quem deseja exportar os XMLs?</p>
                <div style="display: flex; gap: 10px; flex-direction: column; margin-top: 10px;">
                    <p-button
                        icon="pi pi-download"
                        label="Exportar de todas empresas"
                        [pTooltip]="abrasfXMLOption ? 'Apenas o município de Barueri possui suporte para o XML no padrão ABRASF' : ''"
                        (onClick)="downloadPacoteNotas(selectedTipoDocExportacao, true); downloadXMLOpt.hide()"/>
                    <p-button
                        icon="pi pi-download"
                        label="Exportar da empresa selecionada"
                        [pTooltip]="abrasfXMLOption ? 'Apenas o município de Barueri possui suporte para o XML no padrão ABRASF' : ''"
                        (onClick)="downloadPacoteNotas(selectedTipoDocExportacao, false); downloadXMLOpt.hide()"/>
                </div>
            </div>
        </p-overlayPanel>
      </p-fieldset>
    </div>
  </div>
</div>
