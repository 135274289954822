import { AuthService } from '../../../../services/auth.service';
import { Cliente } from 'src/model/cliente.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ControleXMLService } from 'src/services/controle-xml.service';
import { MainComponent } from '../main.component';

enum TipoDoc {
  NFSePrestada = "NFS-e prestadas",
  NFSeTomada = "NFS-e tomadas",
  NFe = "NF-e completas",
  CTe = "CT-e",
  CFe = "CF-e",
  PortalNacional = "Sistema Nacional NFS-e"
};

interface DashboardItem {
  cor: string;
  tipoDoc: TipoDoc;
  quantidadeDocs: number;
  valorTotal: number;
  conteudoCarregou: boolean;
};

interface PanelMenuItem {
  id?: string;
  expanded?: boolean; //controla se itens filhos vao ser mostrados por padrao ou nao
  icon: string;
  textContent?: string;
  badgeContent?: number | undefined;
  items?: PanelMenuItem[];
};

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit, OnDestroy {

  userLogged!: Cliente;

  dashboardContent: DashboardItem[] = [
    {
      cor: "#fff875",
      tipoDoc: TipoDoc.PortalNacional,
      quantidadeDocs: 0,
      valorTotal: 0,
      conteudoCarregou: false
    },
    {
      cor: "#d0e1fd",
      tipoDoc: TipoDoc.NFSePrestada,
      quantidadeDocs: 0,
      valorTotal: 0,
      conteudoCarregou: false
    },
    {
      cor: "#caf1d8",
      tipoDoc: TipoDoc.NFSeTomada,
      quantidadeDocs: 0,
      valorTotal: 0,
      conteudoCarregou: false
    },
    {
      cor: "#ffaca7",
      tipoDoc: TipoDoc.NFe,
      quantidadeDocs: 0,
      valorTotal: 0,
      conteudoCarregou: false
    },
    {
      cor: "#ead6fd",
      tipoDoc: TipoDoc.CTe,
      quantidadeDocs: 0,
      valorTotal: 0,
      conteudoCarregou: false
    },
    {
      cor: "#fcc39b",
      tipoDoc: TipoDoc.CFe,
      quantidadeDocs: 0,
      valorTotal: 0,
      conteudoCarregou: false
    }
  ];

  panelMenuContent: PanelMenuItem[] = [];

  subscriptionPeriodo: any;

  chartData = {
    labels: this.dashboardContent.map(item => { return item.tipoDoc }),
    datasets: [
      {
        label: '',
        backgroundColor: this.dashboardContent.map(item => { return item.cor }),
        data: this.dashboardContent.map(item => { return item.quantidadeDocs })
      }
    ]
  };

  chartOptions = {
    plugins: {
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          color: "#334155"
        }
      }
    }
  };

  empresasLogin: { id: string|undefined, nomeEmpresa: string|undefined }[] = []

  constructor(
    private authService: AuthService,
    private controleXMLService: ControleXMLService,
    private main: MainComponent
  ) { };

  ngOnInit(): void {
    this.userLogged = this.authService.getUsuario()!;
    this.consultaInfos();
    this.renderizaGrafico();

    this.subscriptionPeriodo = this.main.periodoChange.subscribe(() => {
      this.limpaDashboard();
      this.consultaInfos();
    });
  };

  ngOnDestroy(): void {
    this.subscriptionPeriodo?.unsubscribe();
  };

  private consultaInfos() {
    this.carregaValoresControleXML();
    this.consultaInfosNFSe();
    this.consultaInfosDFe();
    this.consultaInfosCTe();
    this.consultaInfosCFe();
    this.consultaInfosPortalNacional();
  };

  private carregaValoresControleXML() {
    const meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho','Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    const mesAtual = meses[this.main.periodo.dataInicio!.getMonth()];

    this.panelMenuContent = [{
      id: "panelMenuRoot",
      icon: "pi pi-file",
      textContent: `Total de XMLs capturados no período de <b>${mesAtual} - ${this.main.periodo.dataInicio?.getFullYear()}</b> :`,
      badgeContent: 0
    }];
  };

  private consultaInfosNFSe() {
    this.controleXMLService.getDadosNFSeDashboard(this.userLogged.login, this.main.periodo.dataInicio?.getFullYear(), this.main.periodo.dataInicio?.getMonth()! + 1).subscribe(infoNFSeList => {
      let infoNotasTomadas = infoNFSeList.filter(infoNota => infoNota.tipoDoc == "NFS-e Tomada");
      let infoNotasPrestadas = infoNFSeList.filter(infoNota => infoNota.tipoDoc == "NFS-e Prestada");

      this.atualizaValores(
        TipoDoc.NFSeTomada,
        infoNotasTomadas.reduce((total, infoNota) => total + infoNota.quantidadeDocs!!, 0),
        infoNotasTomadas.reduce((total, infoNota) => total + infoNota.valorTotalDocs!!, 0),
        true
      );

      this.atualizaValores(
        TipoDoc.NFSePrestada,
        infoNotasPrestadas.reduce((total, infoNota) => total + infoNota.quantidadeDocs!!, 0),
        infoNotasPrestadas.reduce((total, infoNota) => total + infoNota.valorTotalDocs!!, 0),
        true
      );
    });
  };

  private consultaInfosDFe() {
    this.controleXMLService.getDadosDFeDashboard(this.userLogged.login, this.main.periodo.dataInicio?.getFullYear(), this.main.periodo.dataInicio?.getMonth()! + 1).subscribe((infoDFeList) => {
      this.atualizaValores(
        TipoDoc.NFe,
        infoDFeList.reduce((total, infoNota) => total + infoNota.quantidadeDocs!!, 0),
        infoDFeList.reduce((total, infoNota) => total + infoNota.valorTotalDocs!!, 0),
        true
      );
    });
  };

  private consultaInfosCTe() {
    this.controleXMLService.getDadosCTeDashboard(this.userLogged.login, this.main.periodo.dataInicio?.getFullYear(), this.main.periodo.dataInicio?.getMonth()! + 1).subscribe((infoCTeList) => {
      this.atualizaValores(
        TipoDoc.CTe,
        infoCTeList.reduce((total, infoNota) => total + infoNota.quantidadeDocs!!, 0),
        infoCTeList.reduce((total, infoNota) => total + infoNota.valorTotalDocs!!, 0),
        true
      );
    });
  };

  private consultaInfosCFe() {
    this.controleXMLService.getDadosCFeDashboard(this.userLogged.login, this.main.periodo.dataInicio?.getFullYear(), this.main.periodo.dataInicio?.getMonth()! + 1).subscribe((infoCFeList) => {
      this.atualizaValores(
        TipoDoc.CFe,
        infoCFeList.reduce((total, infoNota) => total + infoNota.quantidadeDocs!!, 0),
        infoCFeList.reduce((total, infoNota) => total + infoNota.valorTotalDocs!!, 0),
        true
      );
    });
  };

  private consultaInfosPortalNacional() {
    this.controleXMLService.getDadosPortalNacionalDashboard(this.userLogged.login, this.main.periodo.dataInicio?.getFullYear(), this.main.periodo.dataInicio?.getMonth()! + 1).subscribe((infoPortalNacionalList) => {
      this.atualizaValores(
        TipoDoc.PortalNacional,
        infoPortalNacionalList.reduce((total, infoNota) => total + infoNota.quantidadeDocs!!, 0),
        infoPortalNacionalList.reduce((total, infoNota) => total + infoNota.valorTotalDocs!!, 0),
        true
      );
    });
  };

  private atualizaValores(tipo: TipoDoc, quantidade: any, valor: any, carregou: boolean) {
    this.dashboardContent = this.dashboardContent.map(item => {
      if (item.tipoDoc == tipo) {
        return {
          ...item,
          quantidadeDocs: quantidade,
          valorTotal: valor,
          conteudoCarregou: carregou
        };
      };
      return item;
    });
    this.panelMenuContent[0].badgeContent += quantidade;
    this.renderizaGrafico();
  };

  private limpaDashboard() {
    Object.keys(TipoDoc).forEach(category => {
      const item = TipoDoc[category as keyof typeof TipoDoc];
      this.atualizaValores(item, 0, 0, false);
    })
  }

  private renderizaGrafico() {
    this.chartData = {
      labels: this.dashboardContent.map(item => { return item.tipoDoc }),
      datasets: [
        {
          label: '',
          backgroundColor: this.dashboardContent.map(item => { return item.cor }),
          data: this.dashboardContent.map(item => { return item.quantidadeDocs })
        }
      ]
    };
  };

  escurecerCor(cor: string, percent: number): string {
    return '#' + cor.replace(/^#/, '').replace(/../g, color => {
      const num = parseInt(color, 16) - Math.min(255, Math.round(255 * percent));
      const newColor = num < 0 ? 0 : num;
      return newColor.toString(16).padStart(2, '0');
    });
  };

}
