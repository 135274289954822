import { Emit } from './../../../../model/nfe-to-danfe.model';
import { MensagemService } from './../../../../services/mensagem.service';
import { Component, EventEmitter, Injectable, OnInit, Output } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { NotificacaoModel } from 'src/model/notificacao.model';
import { NotificacaoEventSourceService } from 'src/services/notificacao-event-source.service';
import { cnpj } from 'cpf-cnpj-validator';
import { NotificacoesService } from 'src/services/notificacoes.service';

@Component({
  selector: 'app-notificacoes',
  templateUrl: './notificacoes.component.html',
  styleUrls: ['./notificacoes.component.scss']
})
export class NotificacoesComponent implements OnInit {

  notificacoes: NotificacaoModel[] = [];
  esconderNotificacoes = true;
  qtdNovasNotificacoes = "";

  items!: MenuItem[];

  constructor (
    private notificacaoEventSourceService: NotificacaoEventSourceService,
    private mensagemService: MensagemService,
    private notificacoesService: NotificacoesService
  ) {
    window.addEventListener('click', function(e: any) {
      if (!(e.target.classList.contains('notification') || e.target.classList.contains('notification-list'))) {
        document.getElementById('notification-list')?.setAttribute('style', 'display: none');
      }
    });
  }

  ngOnInit(): void {
    this.connectNotificationService();
  }

  private connectNotificationService() {
    this.notificacoes = [];
    this.qtdNovasNotificacoes = "";
    this.notificacaoEventSourceService.connect().subscribe({
      next: (data: NotificacaoModel[]) => {
        if (data.length > 0) {
          if (data[0].modulo != "Erro") {
            if (this.notificacoes.length == 1 && this.notificacoes[0].modulo == "") {
              this.notificacoes = [];
            }
            this.notificacoes = this.notificacoes.concat(JSON.parse(String(data))).sort((a, b) => {
              var t1 = Number(a.dataRegistro != undefined ? new Date(a.dataRegistro).getTime() : 0);
              var t2 = Number(b.dataRegistro != undefined ? new Date(b.dataRegistro).getTime() : 0);
              return (t2 - t1);
            });
            const ultimaNotificacao = this.notificacoes[0];
            if (ultimaNotificacao.tipoMensagem == 'SUCESSO' && !ultimaNotificacao.lida) {
              this.notificacoesService.onReceiveNovasNotas.emit(ultimaNotificacao.modulo);
            }
            this.qtdNovasNotificacoes = this.getQtdNovasNotificacoes();
          } 
        }
      },
      error: (e: { mensagem: any; }) => {
        console.error(e.mensagem);
        setTimeout(() => {
          this.connectNotificationService();
          console.debug("Reconectando ao serviço de notificações...");
        }, 5000);
      }
    })
  }

  public togglePanelNotifications() {
    setTimeout(() => {
      this.esconderNotificacoes = !this.esconderNotificacoes;
      if (this.notificacoes.length > 0) {
        var ids = this.notificacoes.filter(item => !item.lida).map(item => String(item.id));
        if (ids.length > 0) {
          this.notificacaoEventSourceService.marcaComoLidas(ids).subscribe({
            next: () => {
              this.notificacoes.forEach(n => {
                n.lida = true;
              });
              setTimeout(() => {
                this.qtdNovasNotificacoes = "";
              }, 1000);
            },
            error: (e: { status: number; mensagem: string; message: string; }) => {
              if (e.status == 404 || e.status == 0) {
                this.mensagemService.showMensagemErro("Erro", "Problema na comunicação com o servidor de notificações.");
              } else {
                this.mensagemService.showMensagemErro("Erro", e.mensagem ? e.mensagem : e.message);
              }
            }
          });
        }
      } else {
        let nenhumaNotificacao = new NotificacaoModel();
        nenhumaNotificacao.titulo = "Nenhuma notificação";
        nenhumaNotificacao.modulo = "";
        nenhumaNotificacao.mensagem = "";
        this.notificacoes.push(nenhumaNotificacao);
      }
    }, 30);
  }

  public obterTempoCorrido(data: Date | undefined): string {
    if (data != undefined) {
      var milisegundos = Number(data != undefined ? new Date(data).getTime() : 1000);
      var segundos = (new Date().getTime() - milisegundos) / 1000;
      const duracoes = [ ['ano:anos', 31536000], ['mês:meses', 2628000], ['dia:dias', 86400], ['hora:horas', 3600], ['minuto:minutos', 60], ['segundo:segundos', 1] ];
      var result = '';
      for (const[unidade, duracao] of duracoes) {
        const d = Math.floor(segundos / Number(duracao));
        if (d > 0) {
          return "há " + d + " " + (d > 1 ? unidade.toString().split(":")[1] : unidade.toString().split(":")[0]);
        }
        segundos %= Number(duracao);
      }
      return result;
    } else {
      return '';
    }
  }

  public formatCnpjCpf(value: string | undefined) {
    if (value) {
        return cnpj.format(value);
    } else {
        return value;
    }
  }

  private getQtdNovasNotificacoes(): string {
    var novas = this.notificacoes.filter(item => !item.lida).length;
    return String(novas == 0 ? '': novas);
  }
}
