import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DfeRequest } from 'src/model/dfe-request.model';
import { Cliente } from 'src/model/cliente.model';
import { AuthService } from 'src/services/auth.service';
import { DfeService } from 'src/services/dfe.service';
import { CteService } from 'src/services/cte.service';
import { NFSeService } from 'src/services/nfse.service';
import { NFSePagedRequest } from 'src/model/nfse-paged-request.model';
import { InputRelatorio } from 'src/model/input-relatorio.model';
import { lastValueFrom, timer } from 'rxjs';
import { DFeResponse } from 'src/model/dfe-response.model';
import { Page } from 'src/model/page.model';
import { DadosMunicipioNfseModel } from 'src/model/dados-municipio-nfse.model';
import { NFSeTipo } from 'src/model/enums/nfse-tipo.enum';
import { MunicipiosNfseService } from 'src/services/municipios-nfse.service';
import { MainComponent } from '../main.component';
import { Empresa } from 'src/model/empresa.model';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { CfeService } from 'src/services/cfe.service';
import { CfeRequest } from 'src/model/cfe-request.model';
import { PortalNacionalNFSe } from 'src/model/portal-nacional-nfse.model';
import { PortalNacionalNFSeRequest } from 'src/model/portal-nacional-request.model';
import { PortalNaciocalService } from 'src/services/portal-nacional-nfse.service';

@Component({
  selector: 'app-relatorios',
  templateUrl: './relatorios.component.html',
  styleUrls: ['./relatorios.component.scss']
})
export class RelatoriosComponent implements OnInit {

  userLogged: Cliente;

  selectedEmpresa: Empresa | undefined;

  // Para NFSe
  municipiosContratados: DadosMunicipioNfseModel[] = [];
  tipoNFSe: NFSeTipo = NFSeTipo.TOMADA;
  todosMunicipios: DadosMunicipioNfseModel[] = [];
  selectedMunicipio?: DadosMunicipioNfseModel;

  timeout: any;

  nfe: InputRelatorio = {
    checkRecebidas: true,
    checkEmitidas: false,
    numNotaDe: '',
    numNotaAte: ''
  }

  cte: InputRelatorio = {
    checkRecebidas: true,
    checkEmitidas: false,
    numNotaDe: '',
    numNotaAte: ''
  }

  cfe: InputRelatorio = {
    checkRecebidas: true,
    checkEmitidas: false,
    numNotaDe: '',
    numNotaAte: ''
  }

  portalNacional: InputRelatorio = {
    checkRecebidas: true,
    checkEmitidas: false,
    numNotaDe: '',
    numNotaAte: ''
  }

  nfse: InputRelatorio = {
    checkRecebidas: true,
    checkEmitidas: false,
    numNotaDe: '',
    numNotaAte: ''
  }

  processamentoRelatorio = false;

  dataInicio!: Date;
  dataFim!: Date;

  requestDFe: DfeRequest = new DfeRequest();
  requestNFSe: NFSePagedRequest = new NFSePagedRequest();
  requestCFe: CfeRequest = new CfeRequest();
  requestPortalNacional: PortalNacionalNFSeRequest = new PortalNacionalNFSeRequest();

  daysCheck: any[] = [
    {label: '30 dias', key: '30'},
    {label: '60 dias', key: '60'},
    {label: '90 dias', key: '90'},
    {label: 'Personalizado', key: 'custom'}
  ]

  selectedCheckDate: any = this.daysCheck[0]

  constructor (
    private serviceDFe: DfeService,
    private serviceCTe: CteService,
    private serviceCfe: CfeService,
    private serviceNFSe: NFSeService,
    private servicePortalNacional: PortalNaciocalService,
    private municipiosNfseService: MunicipiosNfseService,
    private messageService: MessageService,
    private authService: AuthService,
    private main: MainComponent
  ) {
    this.userLogged = this.authService.getUsuario()!;
  }

  ngOnInit(): void {
    this.setInputDate(parseInt(this.selectedCheckDate.key) -1 );
    // Carrega todos os municipios para NFSe implementados no sistema
    this.municipiosNfseService.getMunicipios().then(muns => {
      this.todosMunicipios = muns;
      if (this.main.selectedEmpresa) {
        this.selectedEmpresa = this.main.getSelectedEmpresa();
        this.onChangeEmpresa();
      }
    });

    this.main.empresaChange.subscribe((empresa: Empresa) => {
      this.selectedEmpresa = empresa;
      this.requestNFSe.pageNum = 0;
      if (this.tipoNFSe == NFSeTipo.TOMADA) {
        this.requestNFSe.cnpjCpfTomador = this.selectedEmpresa?.id;
      } else {
        this.requestNFSe.cnpjCpfPrestador = this.selectedEmpresa?.id;
      }
      this.carregaCampoMunicipiosContratados();
    });
  }

  customCheck(): boolean{
    if(this.selectedCheckDate.key === 'custom'){
      return false
    }
    return true
  }

  setInputDate(day: number | string): void{
    day = (parseInt(this.selectedCheckDate.key) - 1).toString()
    this.dataFim = moment().toDate()
    this.dataInicio = moment().subtract(day,'days').set({hour:0, minute:0, second:0, millisecond:0}).toDate();
  }

  onChangeEmpresa() {
    this.requestNFSe.pageNum = 0;
    if (this.tipoNFSe == NFSeTipo.TOMADA) {
      this.requestNFSe.cnpjCpfTomador = this.selectedEmpresa?.id;
    } else {
      this.requestNFSe.cnpjCpfPrestador = this.selectedEmpresa?.id;
    }
    this.carregaCampoMunicipiosContratados();
  }

  private carregaCampoMunicipiosContratados() {
    this.municipiosContratados = [];
    if (this.selectedEmpresa) {
      this.selectedEmpresa.modulos.nfseModulo.nfseMunicipios.forEach(dadosMun => {
        const mun = this.todosMunicipios.find(mun => mun.codigo == dadosMun.codigoMun);
        this.municipiosContratados.push(mun!);
      });
      setTimeout(() => {
        this.selectedMunicipio = this.municipiosContratados[0];
        this.requestNFSe.codigoMun = this.selectedMunicipio?.codigo;
      });
    }
  }

  onChangeMunicipio(event: any) {
    this.requestNFSe.codigoMun = event.value.codigo;
  }

  private formatarMoeda(valor: string): string{
    return `R$ ${parseFloat(valor).toFixed(2).replace(".",",")}`
  }

  async baixarRelatorioPlanilha() {
    const qtdRegistrosPorPagina = 1000;

    this.messageService.clear();
    var erro = false;

    if (!this.selectedEmpresa) {
      this.showMensagemAlerta("Seleção de filtros", "Selecione uma empresa para a geração do relatório.");
      erro = true;
    }

    if (!(this.nfe.checkEmitidas || this.nfe.checkRecebidas || this.nfse.checkEmitidas || this.nfse.checkRecebidas || this.cte.checkRecebidas || this.cte.checkEmitidas || this.cfe.checkEmitidas || this.cfe.checkRecebidas || this.portalNacional.checkEmitidas || this.portalNacional.checkRecebidas )) {
      this.showMensagemAlerta("Seleção de filtros", "Deve selecionar pelo menos umas das opções de NFe/NFSe");
      erro = true;
    }

    if (!erro) {
      let excelSheets: any = [];

      const timerShowProgressSpiner = setTimeout(() => {
        this.processamentoRelatorio = true;
        $("#inputsReport :input").attr("disabled", 'true');
      }, 500);

      var checkProgress = {
        _nfe: false,
        set nfe(nfe: boolean) {
          this._nfe = nfe;
          this.checkComplete();
        },
        _cte: false,
        set cte(cte: boolean) {
          this._cte = cte;
          this.checkComplete();
        },
        _nfse: false,
        set nfse(nfse: boolean) {
          this._nfse = nfse;
          this.checkComplete();
        },
        _cfe: false,
        set cfe(cfe: boolean) {
          this._cfe = cfe;
          this.checkComplete();
        },
        _portalNacional: false,
        set portalNacional(portalNacional: boolean){
            this._portalNacional = portalNacional;
            this.checkComplete
        },
        checkComplete() {
          return this._nfe && this._cte && this._nfse && this._cfe && this._portalNacional;
        }
      }

      this.requestDFe.cnpjCpfDestinatario = this.selectedEmpresa?.id;
      this.requestDFe.dataEmissaoInicioStr = this.dataInicio.toISOString().split('T')[0];
      this.requestDFe.dataEmissaoFimStr = this.dataFim.toISOString().split('T')[0];

      this.requestNFSe.dataInicio = this.dataInicio;
      this.requestNFSe.dataFim = this.dataFim;

      this.requestCFe.cnpjEmitente = this.selectedEmpresa?.id;
      this.requestCFe.dataEmissaoInicioStr = this.dataInicio.toISOString().split('T')[0];
      this.requestCFe.dataEmissaoFimStr = this.dataFim.toISOString().split('T')[0];

      this.requestPortalNacional.cnpjEmpresa = this.selectedEmpresa?.id;
      this.requestPortalNacional.dataEmissaoInicioStr = this.dataInicio.toISOString().split('T')[0];
      this.requestPortalNacional.dataEmissaoFimStr = this.dataFim.toISOString().split('T')[0];
      this.requestPortalNacional.municipio = this.selectedMunicipio?.codigo?.toString() || '';

      checkProgress.cfe = !(this.cfe.checkRecebidas || this.cfe.checkEmitidas);
      checkProgress.nfe = !(this.nfe.checkRecebidas || this.nfe.checkEmitidas);
      checkProgress.cte = !(this.cte.checkRecebidas || this.cte.checkEmitidas);
      checkProgress.nfse = !(this.nfse.checkRecebidas || this.nfse.checkEmitidas);
      checkProgress.portalNacional = !(this.portalNacional.checkRecebidas || this.portalNacional.checkEmitidas);

      /**
       * NF-e
       */
      setTimeout(async () => {
        if (this.nfe.checkRecebidas) {
          console.debug("Relatório NF-e => Processamento iniciado.");
          let requestNFe = Object.assign({}, this.requestDFe);
          requestNFe.pageNum = 0;
          requestNFe.pageSize = qtdRegistrosPorPagina;
          requestNFe.numNotaDe = this.nfe.numNotaDe;
          requestNFe.numNotaAte = this.nfe.numNotaAte;

          let nfes: DFeResponse[] = [];

          try {
            let res: Page<DFeResponse> = new Page<DFeResponse>();
            do {
              let excelResult = this.serviceDFe.consultaDFeRelatorio(requestNFe);
              res = await lastValueFrom(excelResult);
              nfes.push(...res.content);
              requestNFe.pageNum += 1;
              if (res.totalElements != 0) {
                console.debug("Relatório NF-e => Processando... " + nfes.length + "/" + res.totalElements);
              }
            } while (!res.last);
            console.debug("Relatório NF-e => Concluido! " + (nfes.length == 0 ? "Nenhum registro encontrado.": ""));
          } catch (e: any) {
            this.showMensagemErro("Erro", e.message);
          }

          excelSheets = this.buildSheetNFe(nfes, excelSheets);

          checkProgress.nfe = true;
          if (checkProgress.checkComplete()) {
            this.saveExcel(excelSheets, timerShowProgressSpiner);
          }
        }
      });

      /**
       * CT-e
       */
      setTimeout(async () => {
        if (this.cte.checkRecebidas) {
          console.debug("Relatório CT-e => Processamento iniciado.");
          let requestCTe = Object.assign({}, this.requestDFe);
          requestCTe.pageNum = 0;
          requestCTe.pageSize = qtdRegistrosPorPagina;
          requestCTe.numNotaDe = this.cte.numNotaDe;
          requestCTe.numNotaAte = this.cte.numNotaAte;

          let ctes: DFeResponse[] = [];

          try {
            let res: Page<DFeResponse> = new Page<DFeResponse>();
            do {
              let excelResult = this.serviceCTe.consultaCTeRelatorio(requestCTe);
              res = await lastValueFrom(excelResult);
              ctes.push(...res.content);
              requestCTe.pageNum += 1;
              if (res.totalElements != 0) {
                console.debug("Relatório CT-e => Processando... " + ctes.length + "/" + res.totalElements);
              }
            } while (!res.last);
            console.debug("Relatório CT-e => Concluido! " + (ctes.length == 0 ? "Nenhum registro encontrado.": ""));
          } catch (e: any) {
            this.showMensagemErro("Erro", e.message);
          }

          excelSheets = this.buildSheetCTe(ctes, excelSheets);

          checkProgress.cte = true;
          if (checkProgress.checkComplete()) {
            this.saveExcel(excelSheets, timerShowProgressSpiner);
          }
        }
      });

        /**
     * CF-e
     */
    setTimeout(async () => {
        if (this.cfe.checkRecebidas) {
            console.debug("Relatório CF-e => Processamento iniciado.");
            let requestCFe = Object.assign({}, this.requestCFe);
            requestCFe.pageNum = 0;
            requestCFe.pageSize = qtdRegistrosPorPagina;
            requestCFe.numNotaDe = this.cfe.numNotaDe;
            requestCFe.numNotaAte = this.cfe.numNotaAte;

            let cfes: CfeRequest[] = [];

            try {
                let res: Page<CfeRequest> = new Page<CfeRequest>();
                do {
                let excelResult = this.serviceCfe.consultaCFeRelatorio(requestCFe);
                res = await lastValueFrom(excelResult);
                cfes.push(...res.content);
                requestCFe.pageNum += 1;
                if (res.totalElements != 0) {
                    console.debug("Relatório CF-e => Processando... " + cfes.length + "/" + res.totalElements);
                }
                } while (!res.last);
                console.debug("Relatório CF-e => Concluido! " + (cfes.length == 0 ? "Nenhum registro encontrado.": ""));
            } catch (e: any) {
                const errorMessage = e?.message || "Ocorreu um erro desconhecido.";
                this.showMensagemErro("Erro", errorMessage);
            }

            excelSheets = this.buildSheetCFe(cfes, excelSheets);

            checkProgress.cfe = true;
            if (checkProgress.checkComplete()) {
                this.saveExcel(excelSheets, timerShowProgressSpiner);
            }
        }
    });

    /**
     * NFS-e Nacional
     */
     setTimeout(async () => {
        if (this.portalNacional.checkRecebidas) {
            console.debug("Relatório NFS-e Nacional => Processamento iniciado.");
            let requestPortalNacional = Object.assign({}, this.requestPortalNacional);
            requestPortalNacional.pageNum = 0;
            requestPortalNacional.pageSize = qtdRegistrosPorPagina;
            requestPortalNacional.numNotaDe = this.portalNacional.numNotaDe
            requestPortalNacional.numNotaAte = this.portalNacional.numNotaAte

            let portais: PortalNacionalNFSeRequest[] = [];

            try {
                let res: Page<PortalNacionalNFSeRequest> = new Page<PortalNacionalNFSeRequest>();
                do {
                let excelResult = this.servicePortalNacional.consultaPortalRelatorio(requestPortalNacional);
                res = await lastValueFrom(excelResult);
                portais.push(...res.content);
                requestPortalNacional.pageNum += 1;
                if (res.totalElements != 0) {
                    console.debug("Relatório NFS-e nacional => Processando... " + portais.length + "/" + res.totalElements);
                }
                } while (!res.last);
                console.debug("Relatório NFS-e nacional => Concluido!" + (portais.length == 0 ? "Nenhum registro encontrado.": ""));
            } catch (e: any) {
                this.showMensagemErro("Erro", e.message);
            }

            excelSheets = this.buildSheetPortalNacional(portais, excelSheets);

            checkProgress.portalNacional = true;
            if (checkProgress.checkComplete()) {
                this.saveExcel(excelSheets, timerShowProgressSpiner);
            }
        }
    });

      /**
       * NFS-e
       */
      setTimeout(async () => {
        if (this.nfse.checkEmitidas || this.nfse.checkRecebidas) {

          if (!this.requestNFSe.codigoMun) {
            this.showMensagemInfo("Relatório NFS-e", "Nenhum muninípio selecionado ou empresa não possui município cadastrado");
            checkProgress.nfse = true;
            return;
          }

          let requestNFSe = Object.assign({}, this.requestNFSe);

          if (this.nfse.checkEmitidas && this.nfse.checkRecebidas) {
            requestNFSe.context = undefined
          } else if (this.nfse.checkEmitidas) {
            requestNFSe.context = NFSeTipo.PRESTADA
            requestNFSe.cnpjCpfTomador = undefined
            requestNFSe.cnpjCpfPrestador = this.selectedEmpresa?.id
          } else {
            requestNFSe.context = NFSeTipo.TOMADA
            requestNFSe.cnpjCpfPrestador = undefined
            requestNFSe.cnpjCpfTomador = this.selectedEmpresa?.id
          }

          console.debug("Relatório NFS-e => Processamento iniciado.");
          requestNFSe.pageNum = 0;
          requestNFSe.pageSize = qtdRegistrosPorPagina;
          requestNFSe.numNotaDe = this.nfse.numNotaDe;
          requestNFSe.numNotaAte = this.nfse.numNotaAte;

          let nfses: NFSePagedRequest[] = [];

          try {
            let res: Page<NFSePagedRequest> = new Page<NFSePagedRequest>();
            do {
              let excelResult = this.serviceNFSe.consultaNFSeRelatorio(requestNFSe);
              res = await lastValueFrom(excelResult);
              nfses.push(...res.content);
              requestNFSe.pageNum += 1;
              if (res.totalElements != 0) {
                console.debug("Relatório NFS-e => Processando... " + nfses.length + "/" + res.totalElements);
              }
            } while (!res.last);
            console.debug("Relatório NFS-e => Concluido! " + (nfses.length == 0 ? "Nenhum registro encontrado.": ""));
          } catch (e: any) {
            this.showMensagemErro("Erro", e.message);
          }

          excelSheets = this.buildSheetNFSe(nfses, excelSheets);

          checkProgress.nfse = true;
          if (checkProgress.checkComplete()) {
            this.saveExcel(excelSheets, timerShowProgressSpiner);
          }
        }
      });
    }
  }

  private buildSheetNFe(dfes: DFeResponse[], excelSheets: any[]) {
    if (dfes.length == 0) {
      this.showMensagemInfo("Relatório NF-e", "Não retornou dados para relatório NF-e com os filtros informados.");
    } else {
      let rows: any = [];
      for (var nfe of dfes) {
        let produto:any;
        let aliqICMS:string;
        let valorICMS:string;
        nfe.listaProdutos.forEach(produtoEimposto => {
          for(var imposto of produtoEimposto.imposto.content){
            const impostoContent = Object.entries(imposto.value); //convertendo o objeto para lista de listas
            impostoContent.forEach(value => { //iterando sob o objeto convertido para lista
              const matchICMS = /icms/; //criando regex para validar objeto ICMS
              if(value[0].match(matchICMS) && value[1] !== null){
                const ICMS:any = value[1];
                aliqICMS = ICMS.picms ?? "0.00"; //se objeto for nulo ou undefined a variavel recebera 0.00
                valorICMS = ICMS.vicms ?? "0.00";
              }
            })
          }
          produto = {...produtoEimposto.prod};
          rows.push({
            col1: nfe.chaveAcesso,
            col2: nfe.cnpjCpfDestinatario,
            col3: nfe.cnpjCpfEmitente,
            col4: nfe.numero,
            col5: nfe.serie,
            col6: nfe.dataEmissao,
            col7: produto.xprod,
            col8: produto.ncm,
            col9: produto.qcom%1?produto.qcom.replace(".",","):Math.round(produto.qcom).toString(),
            /*se o campo quantidade não for um número decimal ele será arredondado para melhor formatação.
            Converti o valor para string para que fique a esquerda no documento excel*/
            col10: this.formatarMoeda(produto.vunCom),
            col11: produto.cfop,
            col12: this.formatarMoeda(aliqICMS),
            col13: this.formatarMoeda(valorICMS)
          });
        })
      }
      const headers = [[
        "Chave de acesso",
        "CNPJ do Destinatário",
        "CNPJ do Emitente",
        "Número da nota",
        "Série da nota",
        "Data de emissão",
        "Descrição do produto",
        "NCM",
        "Quantidade",
        "Valor unitário",
        "CFOP",
        "Alíquota ICMS",
        "Valor ICMS"
      ]];
      excelSheets.push({headers: headers, rows: rows, sheetname: "NF-e"});
    }
    return excelSheets;
  }

  private buildSheetCFe(cfes: CfeRequest[], excelSheets: any[]) {
    if (cfes.length == 0) {
      this.showMensagemInfo("Relatório CF-e", "Não retornou dados para relatório CF-e com os filtros informados.");
    } else {
      let rows: any = [];
      for (var cfe of cfes) {
        //let dataEmissao: string = `${cfe.dataEmissao?.getDate()}/${cfe.dataEmissao!.getMonth()+1}/${cfe.dataEmissao!.getFullYear()}`
          rows.push({
            col1: cfe.status,
            col2: cfe.chaveAcesso,
            col3: cfe.numRecibo,
            col4: cfe.cnpjEmitente,
            col5: cfe.numCF,
            col6: cfe.equipamento,
            col7: cfe.dataEmissao,
            col8: cfe.descriProd,
            col9: cfe.valorNcm,
            col10: cfe.prodQuantidade,
            col11: cfe.valorUnitario,
            col12: cfe.valorCfop,
            col13: cfe.aliquotaIcms,
            col14: cfe.valorIcms
          });
      }
      const headers = [[
        "Tipo",
        "Chave de acesso",
        "Número do recibo",
        "CNPJ do Emitente",
        "Número da nota",
        "Série da nota",
        "Data de emissão",
        "Descrição do produto",
        "NCM",
        "Quantidade",
        "Valor Unitário",
        "CFOP",
        "Alíquota ICMS",
        "Valor ICMS"
      ]];
      excelSheets.push({headers: headers, rows: rows, sheetname: "CF-e"});
    }
    return excelSheets;
  }

  private buildSheetPortalNacional(portais: PortalNacionalNFSeRequest[], excelSheets: any []) {
    if (portais.length == 0) {
        this.showMensagemInfo("Relatório NFS-e Nacional", "Não retornou dados para relatório de notas NFS-e Nacional com os filtros informados.");
      } else {
        let rows: any = [];
        for (var portal of portais) {
            rows.push({
              col1: portal.situacao,
              col2: portal.cnpjEmpresa,
              col3: portal.chaveAcesso,
              col4: portal.municipio,
              col5: portal.nomeEmitente ? portal.nomeEmitente : 'N/A',
              col6: this.formatarMoeda(portal.valorTotal ? portal.valorTotal.toString() : '0'),
              col7: this.formatarMoeda(portal.valorLiquido ? portal.valorLiquido.toString() : '0'),
              col8: portal.descrServ,
              col9: portal.dataEmissaoInicioStr,
            });
        }
        const headers = [[
          "Situação",
          "Emitente",
          "Chave de acesso",
          "Município",
          "Nome do emitente",
          "Valor Total",
          "Valor Liquido",
          "Descrição do serviço",
          "Data Emissão",
        ]];
        excelSheets.push({headers: headers, rows: rows, sheetname: "NFS-e Nacional"});
      }
      return excelSheets;
  }

  private buildSheetCTe(ctes: DFeResponse[], excelSheets: any[]) {
    if (ctes.length == 0) {
      this.showMensagemInfo("Relatório CT-e", "Não retornou dados para relatório CT-e com os filtros informados.");
    } else {
      let rows: any = [];
      for (var nota of ctes) {
        const listaICMS = Object.entries(nota.cte.icms);
        let valorICMS: string;
        listaICMS.forEach(icms => {
          const matchICMS = /icms/;
          if (icms[0].match(matchICMS) && icms[1] !== null) {
            const ICMS: any = icms[1];
            valorICMS = ICMS.vicms ?? "0.00";
          }
        });
        rows.push({
          col1: nota.chaveAcesso,
          col2: nota.cte.nome,
          col3: nota.cte.cpf ?? nota.cte.cnpj,
          col4: nota.cte.cfop,
          col5: this.formatarMoeda(valorICMS!),
          col6: this.formatarMoeda(nota.cte.frete)
        });
      }
      let headers: [string[]] = [[
        "Chave de acesso",
        "Nome do cliente",
        "CPF/CNPJ",
        "CFOP",
        "ICMS",
        "Valor do frete"
      ]];
      excelSheets.push({headers: headers, rows: rows, sheetname: "CT-e"});
    }
    return excelSheets;
  }

  private buildSheetNFSe(nfses: NFSePagedRequest[], excelSheets: any[]) {
    if (nfses.length === 0) {
      this.showMensagemInfo("Relatório NFS-e", "Não retornou dados para relatório NFS-e com os filtros informados.");
    } else {
      let rows: any = [];
      nfses.forEach(nfse => {
        let dataEmissaoFormatted = nfse.dataEmissao ? new Date(nfse.dataEmissao).toLocaleDateString('pt-BR') :  nfse.dataEmissao ;
        var valorISS = nfse.valorISS ?? "0.00";
        rows.push({
          col1: dataEmissaoFormatted,
          col2: nfse.numero.toString(),
          col3: nfse.municipio,
          col4: nfse.nomePrestador,
          col5: nfse.cnpjCpfPrestador,
          col6: nfse.nomeTomador,
          col7: nfse.cnpjCpfTomador,
          col8: nfse.codigoServico,
          col9: nfse.descricao,
          col10: this.formatarMoeda(nfse.valor!.toString()),
          col11: this.formatarMoeda(valorISS.toString())
        });
      })
      let headers:[string[]] = [[
        "Data emissão",
        "Número da NFSe",
        "Município",
        "Nome do prestador",
        "CPF/CNPJ do prestador",
        "Nome tomador",
        "CPF/CNPJ do tomador",
        "Código do serviço",
        "Descrição dos serviços",
        "Valor do serviço",
        "Valor ISS"
      ]];
      excelSheets.push({headers:headers, rows:rows, sheetname:"NFS-e"});
    }
    return excelSheets;
  }

  private saveExcel(excelSheetsArray: [{headers: [String[]], rows: any[], sheetname: string}], timeout: NodeJS.Timeout) {
    if (excelSheetsArray.length > 0) {
      // Create workbook and worksheet
      let workbook = XLSX.utils.book_new();

      excelSheetsArray.forEach(sheet => {
        let worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(worksheet, sheet.headers);

        // Setting default pixel width for columns
        var wscols: any = [];
        for (var title of sheet.headers[0]) {
          switch (title) {
            case "Descrição do produto":
            case "Nome do cliente":
            case "Nome do prestador":
            case "Descrição dos serviços":
              wscols.push({wpx: 200})
              break;
            case "CPF/CNPJ do prestador":
              wscols.push({wpx: 130});
              break;
            case "Chave de acesso":
              wscols.push({wpx: 290});
              break;
            default:
              wscols.push({wpx: 110});
          }
        }

        worksheet['!cols'] = wscols;
        XLSX.utils.sheet_add_json(worksheet, sheet.rows, { origin: 'A2', skipHeader: true });
        XLSX.utils.book_append_sheet(workbook, worksheet, sheet.sheetname);
      });

      XLSX.writeFile(workbook, "relatorio_" + this.selectedEmpresa!.id + ".xlsx", {compression: true});
      this.showMensagemSucesso("", "Relatório gerado com sucesso, download realizado.");
    }
    this.processamentoRelatorio = false;
    clearTimeout(timeout);
  }

  private showMensagemErro(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'to-message-service',
      severity: 'error',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 10000
    });
  }

  private showMensagemAlerta(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'to-message-service',
      severity: 'warn',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 10000
    });
  }

  private showMensagemInfo(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'to-message-service',
      severity: 'info',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 10000
    });
  }

  private showMensagemSucesso(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'to-message-service',
      severity: 'success',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 10000
    });
  }
}
