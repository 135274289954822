import { NFSeTipo } from './enums/nfse-tipo.enum';

export class NFSeConsultaWsRequest {

    context!: NFSeTipo;
    clienteLogin?: String;
    empresaCnpj?: String;
    codigoMun!: Number | undefined;
    dataInicio?: Date;
    dataFim?: Date;
    dataConsulta: String | undefined;
}
