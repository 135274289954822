import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ControleDownloadXML } from 'src/model/controle-download-xml.model';
import { DashboardReportDTO } from 'src/model/dashboard-report.model';

const ENDPOINT_CONTROLE = environment.API_MAIN + "/controle-xml";
const ENDPOINT_DASHBOARD_NFSES = environment.API_MAIN + "/controle-xml/nfse/consulta-dashboard";
const ENDPOINT_DASHBOARD_DFES = environment.API_MAIN + "/controle-xml/dfe/consulta-dashboard";
const ENDPOINT_DASHBOARD_CTES = environment.API_MAIN + "/controle-xml/cte/consulta-dashboard";
const ENDPOINT_DASHBOARD_CFES = environment.API_MAIN + "/controle-xml/cfe/consulta-dashboard";
const ENDPOINT_DASHBOARD_PORTAL_NACIONAL = environment.API_MAIN + "/controle-xml/portal-nacional/consulta-dashboard";

@Injectable({
  providedIn: 'root'
})
export class ControleXMLService {

  constructor(
    private http: HttpClient
  ) { }

  public getControleXML(clienteLogin: String, ano: number|undefined, mes: number|undefined): Observable<ControleDownloadXML> {
    return this.http.get<ControleDownloadXML>(`${ENDPOINT_CONTROLE}/${clienteLogin}/${ano}/${mes}`);
  }

  public getDadosNFSeDashboard(clienteLogin: String, ano: number|undefined, mes: number|undefined): Observable<Array<DashboardReportDTO>> {
    return this.http.get<Array<DashboardReportDTO>>(`${ENDPOINT_DASHBOARD_NFSES}/${clienteLogin}/${ano}/${mes}`);
  }

  public getDadosDFeDashboard(clienteLogin: String, ano: number|undefined, mes: number|undefined): Observable<Array<DashboardReportDTO>> {
    return this.http.get<Array<DashboardReportDTO>>(`${ENDPOINT_DASHBOARD_DFES}/${clienteLogin}/${ano}/${mes}`);
  }

  public getDadosCTeDashboard(clienteLogin: String, ano: number|undefined, mes: number|undefined): Observable<Array<DashboardReportDTO>> {
    return this.http.get<Array<DashboardReportDTO>>(`${ENDPOINT_DASHBOARD_CTES}/${clienteLogin}/${ano}/${mes}`);
  }

  public getDadosCFeDashboard(clienteLogin: String, ano: number|undefined, mes: number|undefined): Observable<Array<DashboardReportDTO>> {
    return this.http.get<Array<DashboardReportDTO>>(`${ENDPOINT_DASHBOARD_CFES}/${clienteLogin}/${ano}/${mes}`); 
  }

  public getDadosPortalNacionalDashboard(clienteLogin: String, ano: number|undefined, mes: number|undefined): Observable<Array<DashboardReportDTO>> {
    return this.http.get<Array<DashboardReportDTO>>(`${ENDPOINT_DASHBOARD_PORTAL_NACIONAL}/${clienteLogin}/${ano}/${mes}`); 
  }
}
