import { PageRequest } from "./page-request.model";

export class PortalNacionalNFSeRequest extends PageRequest {

  chaveAcesso: string | undefined;
  cnpjEmpresa: string | undefined;
  clienteLogin: string | undefined;
  ultNsu: number | undefined;
  sortField: string | string[] | undefined;
  sortOrder: string | undefined;
  dataInicio: Date | undefined;
  dataFim: Date | undefined;
  dataEmissaoInicioStr: string | undefined;
  dataEmissaoFimStr: string | undefined;
  dataConsulta: String | undefined;
  situacao: String | undefined;
  municipio!: String;
  nomeEmitente!: String;
  valorTotal!: Number | String;
  valorLiquido!: Number | String;
  descrServ: string | undefined;
  numNotaDe!: number | string;
  numNotaAte!: number | string;
}
